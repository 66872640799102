

/* Banner */

#banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: default;
    height: 50vh;
    min-height: 20em;
    overflow: hidden;
    position: relative;
    text-align: center;
}

#banner .inner {
    background-position: right top;
    height: 100%;
    background-size: 80%;
    background-repeat: no-repeat;
}

svg {
    .later,
    .later1,
    .later2,
    .later3,
    .sf {
     display: none;
    }
}


#tds-svg {
    height: 56vh;
    width: 57vh;
    top: -17vh;
    left: 46%;
    position: absolute;
    z-index: 6;

}

// #banner h2 {
//     transform: scale(1);
//     transition: transform 1.0s ease, opacity 1s ease;
//     transition-delay: 0.5s;
//     display: inline-block;
//     font-size: 1.55em;
//     font-weight: 100;
//     opacity: 1;
//     padding: 0.35em 1em;
//     position: relative;
//     z-index: 1;
//     top: 3.5vh;
//     text-shadow: 0 0 3px black;
// }

// #banner p {
//     letter-spacing: 0.225em;
//     text-transform: uppercase;
// }

// #banner p a {
//     color: inherit;
//     text-shadow: 0 0 3px black;
// }

// #banner .more {
//     transition: transform 0.75s ease, opacity 0.75s ease;
//     transition-delay: 1.5s;
//     transform: translateY(0);
//     border: none;
//     bottom: -1em;
//     color: inherit;
//     font-size: 0.8em;
//     height: 8.5em;
//     left: 50%;
//     letter-spacing: 0.225em;
//     margin-left: -8.5em;
//     opacity: 1;
//     outline: 0;
//     padding-left: 0.225em;
//     position: absolute;
//     text-align: center;
//     text-transform: uppercase;
//     width: 16em;
//     z-index: 1;
//     text-shadow: 0 0 3px black;
// }

// #banner .more:after {
//     background-image: url("/images/arrow.svg");
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: contain;
//     bottom: 4em;
//     content: '';
//     display: block;
//     height: 1.5em;
//     left: 50%;
//     margin: 0 0 0 -0.75em;
//     position: absolute;
//     width: 1.5em;
// }

// #banner:after {
//     pointer-events: none;
//     transition: opacity 3s ease-in-out;
//     content: '';
//     background: #000;
//     display: block;
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     left: 0;
//     top: 0;
//     opacity: 0;
// }

@media screen and (max-width: 736px) {
    #banner {
        padding: 10em 3em 5em 3em;
        height: auto;
        min-height: 0;
    }

    #banner .inner {
        position: absolute;
        right: -2em;
        top: -3em;
        width: 100%;
        height: 14em;

    }

    #tds-svg {
        height: 35vh;
        width: 35vh;
        top: -13vh;
        left: 53%;
        position: absolute;
        z-index: 1;
    }

    // #banner h2 {
    //     font-size: 1.25em;
    // }
    // #banner br {
    //     display: none;
    // }
    // #banner .more {
    //     display: none;
    // }
}

@media screen and (max-width: 460px) {
    #banner {
        padding: 7em 3em 5em 3em;
        height: auto;
        min-height: 0;
    }

    #tds-svg {
        height: 38vh;
        width: 32vh;
        top: -16vh;
        left: 45%;
        position: absolute;
        z-index: 1;
    }

    // #banner h2 {
    //     font-size: 1.25em;
    // }
    // #banner br {
    //     display: none;
    // }
    // #banner .more {
    //     display: none;
    // }
}


body.is-loading #banner h2 {
    transform: scale(0.80);
    opacity: 0;
}

body.is-loading #banner h2:before,
body.is-loading #banner h2:after {
    width: 0;
}

body.is-loading #banner .more {
    transform: translateY(8.5em);
    opacity: 0;
}

body.is-loading #banner:after {
    opacity: 1;
}




