section.events-section {
    padding-top: 0;
}

.events-sorter {
    a {
        cursor: pointer;

        &.active {
            font-weight: bold;;
        }
    }
}

#dates,
#map {

    // merken
    .collected {
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2312ff00' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    }

    // groups
    h2 {
        color: #000;
        font-weight: bold;
        letter-spacing: .05em;
        margin-bottom: 0em;
        margin-top: 2em;
    }

    .accordion {

    }

    .date-holder {
        border-top: 2px solid #60f50d;
        padding: 1.3em 0;

        &:last-Child{
            border-bottom: 2px solid #60f50d;
            margin-bottom: 3em;
        }
    }


    .box {
        width: 100%;
        display: flex;
    }

    .img {
        flex-basis: 38.2%;
        
        img {
            width: 100%;
        }
    }

    .date-city {
        font-weight: bold;
        color: #333;
    }

    .desc {
        flex-basis: 61.8%;
        padding-left: 2em;
    }



    h3 {
        color: #52e400;
        text-transform: none;
        font-weight: bold;
        margin: 0.1em 0 0em 0;
        letter-spacing: .05em;
        line-height: 1.45em;
    }
    p {
        margin: 0;

        &.subline {
            letter-spacing: 0.045em;
        }
    }

    .description {
        //padding: .7em 0;
        padding: 1.2em 0 0.5em;
    }


    .loc-holder {
        width: 100%;
        display: flex;
    }

    .location {
        flex-basis: 61.8%;
        padding: .7em 0;
        color: #60f50d;
    }


    .showOnMap {
        flex-basis: 38.2%;
        text-align: right;
        color: #52e400;
        padding: .7em 0 ;
        display: inline-block;
        border-bottom: 0 !important;
        line-height: 1.65em;

        &:hover {
            color: #000;
        }

        svg {
            width: 1.3em;
            height: auto;
            margin-bottom: -0.3em !important;

            path {

                fill: #52e400;
            }
        }
    }

    .addToCollection {
        color: #52e400;
        border-bottom: 0;
        display: block;
        padding: 0.7em 0 0.5em 0;

        &:hover {
            color: #000;
        }

        svg {
            width: 1.2em;
            height: auto;
            margin-bottom: -0.3em !important;

             path {

                stroke: #52e400;
                fill: none;
            }
        }

    }
}


#dates .collected .addToCollection{
    svg path {
        fill: #52e400;
    }   
}



#map {
        
        h3 {
            margin: 1.3em 0 1em;
        }



        .date-holder {
            border: none !important;
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }

        .box {
            //flex-direction: column;
            max-height: 250px;
            overflow: scroll;
        }

        .location {
            padding: 0.3em 0;
        }

        .description {
            padding: 0.8em 0 .5em;
        }

        // .img {
        //     width: 100%;
            
        // }

        // .desc {
        //     width: 100%;
        //     padding-left: 0;
        // }

        .leaflet-container a.leaflet-popup-close-button {
            text-align: right;
            width: 32px;
            height: 32px;
        }



    }


@media screen and (max-width: 736px){

    #dates {
        .box {
            flex-direction: column;
        }
        .img {
            width: 100%;
            margin-bottom: .8em;
        }

        .desc {
            width: 100%;
            padding-left: 0;
        }

        .city  {
            display: block;
        }

    }

    #map {
        .img {
            //display: none;
        }
    }

}