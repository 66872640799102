@media print {

	.menu-btn,
	#header ,
	#tds-svg,
	#banner,
	footer {
		display: none !important;
	}

	body { 
		width: 18cm;
		font-size: 10pt !important;
	}

	.wrapper {
	    padding: 0 0 1cm !important;
	}


}