
/* Landing */

body.landing #page-wrapper {
    // background-image: url("../../images/Karte_1.jpg");
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 0;
}

body.landing #banner {
    //background-image: url("../../images/1920/IBA_SommerfrischeMotive_Universal01.jpg"); // js: rotate images
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;


    // svg {
    //     stroke: #fff;
    //     stroke-width: 3;
    // }
}

.menu-btn{
    color: #a9a9a9;
    display: none;
    position: fixed;
    top: 1em;
    left: 1.2em;
    z-index: 10003;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 0.1em;
    text-decoration: none;
    border-bottom: none;
}
@media screen and (max-width: 736px){
    .menu-btn{
        display: block;
    }
    #header {
        display: none;
    }
}


// future
.intro-1 {

}

// now
.intro-2 {
    display: none;
}

// past
.intro-3 {
    display: none;
}

.sponsoren {
    margin: 1.2em 0 1.6em;

    img{
        width: 45%;
        display: inline-block;
    }
    img:first-child{
        margin-right: 2%; 
    }
}
@media screen and (max-width: 480px) {
    .sponsoren img{
        width: 100%;
    }
    .sponsoren img:first-child{
        margin-bottom: .7em; 
    }
}
@media screen and (min-width: 1080px) {
    .sponsoren img{
        width: 30%;
    }
}

/* Map */

#map {
    height: 80vh;
    width: 100%;
}


// sommerfrische
.zero-bottom-spacing {
    margin-bottom: 0;
}

.sf-more {
    p {
        border-bottom: 1px dotted;
        display: inline-block;
    }

    &:after {
        top: 4px !important;
    }
}

p strong {
    color: #000;
}



.contact {

    h3 {
        color: #000;
        line-height: 1.3em;
        letter-spacing: 0.14em;
        margin-bottom: 0.3em;
        margin-top: 3em;

        strong {
            color: #60f50d;
        }
    }

    p {
        font-size: 1.1em;
    }

    .tel {
        display: inline-block;
        width: 2.4em;
    }

    .no-bottom-spacing {
        margin-bottom: 0;
        padding-bottom: 0.7em;
    }

}

.maps-panel {

    .wrapper header {
        //position: absolute;
    }

    img {
        width: 100vw;
    }

    img.detail {
        padding-top: 2em;
    }
}
@media screen and (max-width: 736px){
    img.europe {
        padding-top: 2em;
    }

}


.b-lazy {
    transition: opacity 500ms ease-in-out;
    max-width: 100%;
    opacity: 0;
}

.b-lazy.b-loaded {
    opacity: 1;
}


header h2 strong {
    color: #60f50d !important;
}

.img-wrap {
    //font-size: 0; // prevent space btw. images
}

img {
    display: block;
    // width: 100vw;
    // height: 75vw;
}

img.iba-logo {
    width: 10em;

    margin-bottom: 3em;
    margin-top: 3em;
}

h3 {
    //color: $highlight_color;
}


// break long urls
p {
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    //word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}





.img-wrap img {
    width: 100vw;
    height: 75vw;
}

// landing
.panel0 {
    background-color: #000;
    color: #fff;

    h2, a, p {
        color: #fff;
    }
}


.text-panel {
    background-color: #fff;
    color: #000;

    p {
        color: #000;
        line-height: 1.65em;
    }


    // svg {
    //     stroke: #000;
    //     stroke-width: 3;
    // }

}

section.review {
    .slides {
        clear: both;
        overflow: hidden;
    }
    
    a {
        width: 202px;
        height: 202px;
        overflow: hidden;
        display: block;
        float: left;
        margin-right: .5em;
        margin-bottom: .5em;
        border: 1px solid transparent;
        border-radius: 1px;
        //box-shadow: 1px 1px 3px rgba(100,100,100,0.1);

        &:hover {
            border: 1px solid #60f50d;
        }

        &:focus {
            outline: 0
        }

    }
}

.wrapper {
    padding: 6em 0 4em;
    &.alt {
        padding: 0;
    }

    > .inner {
        width: 60em;
        margin: 0 auto;
    }
}


@media screen and(max-width: 1280px) {
    .wrapper > .inner {
        width: 90%;
    }
}
@media screen and(max-width: 980px) {
    .wrapper {
        padding: 4em 2em 2em;
        > .inner {
            width: 100%;
        }
    }
}



footer {
    width: 100%;
    background-color: #000;

    nav {

        & > ul {
            text-align: center;
            list-style: none;
            margin: 0 1em;
            padding: 0;
            white-space: nowrap;

            & > li {
                display: inline-block;
                padding: 0;
                text-shadow: 0 0 4px #2e3842;

                & > a {
                    border: 0;
                    color: #aaa;
                    display: block;
                    font-size: 0.7em;
                    letter-spacing: 0.225em;
                    padding: 0 1.5em;
                    text-transform: uppercase;
                    font-weight: normal;
                }

            }

        }

    }

}
